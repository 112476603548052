<template>
  <a :href="blok.link" target="_blank" aria-label="Storyblok icon link">
    <Icon class="icon" :icon="blok.name" />
  </a>
</template>
<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "IconSb",
  components: {
    Icon,
  },
  props: {
    blok: {
      type: Object,
      required: false,
      default: {},
    },
  },
};
</script>
<style scoped>
.icon {
  font-size: 30px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
