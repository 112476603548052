<template>
  <div class="relative hero">
    <div v-if="blok.hero_img_mobile" class="mobile-hero">
      <img :src="blok.hero_img_mobile.filename + '/m/filters:format(webp)'" alt="hero image" loading="lazy"/>
    </div>
    <div class="thumbnail relative">
      <VueSlickCarousel
        v-bind="thumbnailOptions"
        ref="slider"
        @afterChange="updateCurrentIndex($event)"
      >
        <div
          v-for="(item, index) in blok.blocks"
          :key="index"
          class="slide-wrapper relative"
          aria-hidden="true"
        >
          <div
            class="h-[100%]"
            v-if="item.banner_link && item.banner_link.url.length > 0"
          >
            <nuxt-link
              v-if="convertToNuxtLink(item.banner_link.url)"
              :to="convertToNuxtLink(item.banner_link.url)"
              aria-label="Link from hero slider"
              draggable="false"
            >
              <img
                quality="90"
                format="webp"
                placeholder
                :src="item.image.filename + '/m/fit-in/1900x450/filters:format(webp)'"
                class="w-full"
                alt="slider image"
                draggable="false"
                loading="lazy"
              />
            </nuxt-link>
            <a v-else :href="item.banner_link.url" target="_blank" draggable="false">
              <nuxt-img
                quality="90"
                format="webp"
                placeholder
                :src="item.image.filename + '/m/fit-in/1900x450/filters:format(webp)'"
                alt="slider image"
                draggable="false"
                loading="lazy"
              />
            </a>
          </div>
          <nuxt-img
            v-else
            quality="90"
            format="webp"
            placeholder
            :src="item.image.filename + '/m/fit-in/1900x450/filters:format(webp)'"
            class="w-full"
            alt="slider image"
            draggable="false"
            loading="lazy"
          />

          <div class="container relative">
            <p
              v-if="item.main_image_text"
              class="main-image-text top-text"
              :class="item.btn_black ? 'text-black' : 'text-white'"
            >
              {{ item.main_image_text.split(" ").slice(0, 4).join(" ") }}
            </p>
            <p
              v-if="item.main_image_text && item.main_image_text.split(' ').length > 4"
              class="main-image-text"
              :class="item.btn_black ? 'text-black' : 'text-white'"
            >
              {{ item.main_image_text.split(" ").slice(4).join(" ") }}
            </p>
            <div v-if="item.button_link.url" class="hero-btn">
              <nuxt-link
                v-if="convertToNuxtLink(item.button_link.url)"
                :to="convertToNuxtLink(item.button_link.url)"
                aria-label="Link from hero slider"
              >
                <span
                  v-if="item.button_text"
                  class="discover-btn btn-white"
                  :class="item.btn_black ? 'black-button' : 'white-button'"
                >
                  {{ item.button_text }}
                </span>
              </nuxt-link>
              <a
                v-else
                :href="item.button_link.url"
                aria-label="Link from hero slider"
                target="_blank"
              >
                <span
                  v-if="item.button_text"
                  class="discover-btn btn-white"
                  :class="item.btn_black ? 'black-button' : 'white-button'"
                >
                  {{ item.button_text }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>
<script>
import HeroSlide from "./HeroSlide";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Hero",
  components: {
    HeroSlide,
    VueSlickCarousel,
  },
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
  data() {
    return {
      thumbnailOptions: {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        asNavFor: this.$refs.slider,
        initialSlide: 0,
        lazyLoad: 'ondemand',
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      currentIndex: {
        value: 0,
      },
      mounted: false,
    };
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    updateCurrentIndex(e) {
      this.currentIndex.value = e;
    },
    convertToNuxtLink(url) {
      // Extract path and query parameters from the URL
      const urlObj = new URL(url);

      const path = urlObj.pathname;
      const query = urlObj.searchParams.toString();

      if (urlObj.origin.includes("shots")) {
        const nuxtLink = `${path}${query ? `?${query}` : ""}`;

        return nuxtLink;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.hero {
  @apply flex max-w-[1920px] mx-auto;
  overflow: hidden;
  height: 450px;
  .mobile-hero {
    display: none;
  }
  @media screen and (max-width: 1440px) {
    max-width: 100%;
  }

  @media screen and (max-width: 1280px) {
    padding: 0;
    max-width: 100%;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0;
  }
  @media only screen and (max-width: 768px) {
    .mobile-hero {
      display: block;
    }
    .thumbnail {
      display: none;
    }
  }
  .thumbnail ::v-deep .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 40px;
    @media screen and (max-width: 768px) {
      bottom: 15px;
    }
    li {
      // width: 185px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #ffffff;
      opacity: 0.7;
      margin: 0 10px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      button {
        display: none;
      }
    }
    .slick-active {
      display: block;
      opacity: 1;
    }

    .slick-dots.slick-dots-sliding {
      transition: all 0.5s ease-in-out;
    }
  }
  .thumbnail {
    width: 100%;
    @media screen and (max-width: 1024px) {
      overflow: hidden;
    }
    .slide-wrapper {
      height: 450px;
      img {
        object-fit: cover;
        height: 100%;
        @media screen and (max-width: 1024px) {
          height: 400px;
          margin-bottom: -8px;
          @media screen and (max-width: 768px) {
            height: auto;
            object-fit: cover;
            object-position: left center;
          }
        }
      }
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 400px;
      .slide-wrapper {
        height: 100%;
      }
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      height: auto;
      .slide-wrapper {
        height: 100%;
      }
    }
  }
  .main-image-text {
    @apply absolute font-bold font-mont text-4xl;
    bottom: 10rem;
    left: 0;
    &.top-text {
      bottom: 13rem;
    }

    @media screen and (max-width: 1280px) {
      bottom: 9rem;
      left: 30px;
      &.top-text {
        bottom: 12rem;
      }
    }

    @media screen and (max-width: 992px) {
      @apply text-lg;
      bottom: 7rem;
      &.top-text {
        bottom: 8rem;
      }
    }
    @media screen and (max-width: 480px) {
      @apply text-base;
      bottom: 5rem;
      &.top-text {
        bottom: 6rem;
      }
    }
  }
  .hero-btn {
    @apply absolute;
    bottom: 4rem;
    @media screen and (max-width: 1280px) {
      left: 30px;
    }
    @media screen and (max-width: 1024px) {
      bottom: 3rem;
    }
    @media screen and (max-width: 480px) {
      bottom: 2rem;
    }
  }
  .discover-btn {
    background: transparent;
    &.white-button {
      border: 3px solid #fff;
      color: white;
      &:hover {
        box-shadow: 0 1px 1px #fff;
      }
      &:active {
        box-shadow: 0 0px 0px #fff;
      }
    }
    &.black-button {
      border: 3px solid #261717;
      color: #261717;
      &:hover {
        box-shadow: 0 1px 1px #261717;
      }
      &:active {
        box-shadow: 0 0px 0px #261717;
      }
    }
    @media screen and (max-width: 1024px) {
      @apply px-4 py-2 text-base;
    }
    @media screen and (max-width: 768px) {
      @apply px-2 py-1 text-sm;
    }
    @media screen and (max-width: 480px) {
      @apply px-2 py-1 text-xs;
    }
  }

  .thumbnail-slider {
    flex-basis: 70%;
    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }
    &::v-deep .slick-slide {
      border: none;
      .hero-slide {
        opacity: 0.6;
      }

      &.slick-current {
        .hero-slide {
          opacity: 1;
        }
      }
      .side-wrapper {
        max-width: 40vw;
        display: block !important;
        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
        @media screen and (max-width: 1024px) {
          max-width: 100%;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      max-height: 600px;
    }

    @media screen and (max-width: 1024px) {
      overflow: hidden;
    }
  }
}
</style>
