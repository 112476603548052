<template>
  <div class="flex relative hero-slide hover:cursor-pointer">
    <div class="relative hero-slide-img">
      <nuxt-img
        quality="90"
        format="webp"
        placeholder
        :src="blok.image.filename"
        alt="slider image"
      />
    </div>
    <div class="hero-slide-text ml-2 md:ml-4">
      <h3 class="mont title text-brownish-grey font-bold text-base">
        {{ blok.title }}
      </h3>
      <div
        v-html="$storyapi.richTextResolver.render(blok.text)"
        class="slide-text text-xs font-fgb"
      />
      <div v-if="blok.button_link.url">
        <a :href="blok.button_link.url" aria-label="Link from hero slider">
          <button
            class="read-more text-brownish-grey border px-2 rounded-3xl text-base font-mont"
          >
            Read more
          </button>
        </a>
      </div>
      <div v-else>
        <nuxt-link :to="localePath('/' + blok.button_link.cached_url)">
          <button
            class="read-more text-brownish-grey border px-2 rounded-3xl text-base font-mont"
          >
            Read more
          </button>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeroSlide",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
};
</script>
<style scoped lang="scss">
.hero-slide {
  @apply relative;
  height: 150px;
  .hero-slide-text {
    @apply flex flex-col justify-start;
    width: 100%;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-top: 20px;
    ::v-deep {
      p {
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 3.6em;
        line-height: 1.8em;
      }
    }
    @media screen and (max-width: 1024px) {
      max-width: 50%;
    }
  }
  .read-more {
    @apply px-6 py-1 font-bold absolute;
    bottom: 6px;
    font-size: 14px;
    line-height: normal;
  }
  .slide-text {
    line-height: 1.5rem;
    @media screen and (max-width: 1440px) {
      line-height: 1.1rem;
    }
  }
  @media screen and (max-width: 1024px) {
    height: 200px;
    .title {
      @apply text-xs;
    }
    .hero-slide-text {
      width: 90%;
    }
    .slide-text {
      font-size: 12px;
      padding: 4px 0;
    }
  }
}
.hero-slide-img {
  width: 500px;
  max-width: 20vw;

  @media screen and (max-width: 1240px) {
    max-width: 14vw;
  }
  @media screen and (max-width: 1024px) {
    max-width: 50%;
    img {
      object-fit: cover;
    }
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
</style>
